<template>
  <div>
    <transition enter-active-class="animate__animated animate__fadeIn"
                appear>
      <div>
        <div>
          <v-img  style="position: relative;background-color: #121b3c" width="100%" min-height="320px" src="../assets/image/Ads/head.jpg" max-height="500px" >
            <template v-slot:default>
              <div class="centeredtitle">
                <h1  class="adstitle font-weight-regular">بانک اخبار‌</h1>
                <h2  class="adssubtitle font-weight-regular">اخبار شرکت های فعال در سامانه ایران آی سیب</h2>
              </div>
            </template>
          </v-img>
        </div>

        <v-container class="pa-0">
          <div style="position: relative">
            <div class="d-flex flex-md-nowrap flex-wrap align-center searchbar" style="padding-left: 12.75px;padding-right: 12.75px">
              <div class="catstitle mx-md-1">
                <v-text-field
                    label="عنوان مورد نظر"
                    style="width: 100%;border-radius: 8px;"
                    solo
                    v-model="str"
                    height="45"
                    hide-details
                >
                </v-text-field>
              </div>
              <div class="catsselect mx-md-1">
                <v-select
                    label="دسته بندی اخبار"
                    style="width: 100%;border-radius: 8px;"
                    solo
                    v-model="selectedcatId"
                    :items="cats"
                    item-text="title"
                    item-value="id"
                    color="#f4f4f4"
                    height="45"
                    hide-details
                >
                  <template v-slot:append>
                    <img src="../assets/image/Cat/icon-down.png" height="11.5" width="20"/>
                  </template>
                </v-select>
              </div>
              <div >
                <v-btn
                    height="45"
                    min-width="155"
                    @click="search"
                    class=" btn mb-3 mb-md-0 mx-md-1"
                    style="font-size: 18px !important;"
                >
                  جستجو
                  <img src="../assets/image/Public Icons/serach-icon.png" class="mr-5" height="24.8" width="24"/>
                </v-btn>
              </div>
            </div>


          </div>
        </v-container>

        <v-container class="d-flex adscontent">
          <div class="side-col pa-0 d-none d-md-block">
            <SideMenu>
              <img slot="image" src="../assets/image/Public Icons/Side icons/icon.png" height="45" width="45"/>
              <span slot="title" >دسته بندی اخبار</span>
              <div slot="contant" >
                <div v-for="cat in cats" :key="cat.id+'side'" >
                  <router-link :to="'/news?cat='+cat.id" @click="selectedcatId=cat.id" class="sidecattext textlinkhover ma-0" >{{cat.title}}</router-link>
                  <v-divider class="my-6"></v-divider>
                </div>
              </div>
            </SideMenu>

          </div>
          <div class="contant-col pr-md-6">

            <p v-if="!workloading & items.length===0" class="text-center mt-10" >موردی جهت نمایش وجود ندارد</p>
            <div v-if="workloading" class="d-flex justify-center">
              <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>

            <div class="d-flex flex-wrap justify-space-between">
              <div v-for="item in items" :key="item.id" class="adscard">
                <div class="adscardtitle">
                  <router-link :to="{name:'singlenews',params:{id:item.id,title:item.title}}" custom v-slot="{navigate,href}">
                  <a @click="navigate" :href="href" role="link" class="ma-0 font-weight-bold textlinkhover">{{ item.title }}</a>
                  </router-link>
                </div>
                <div class="d-flex flex-nowrap justify-space-between align-start">
                  <div>
                    <p class="primary--text mb-1" style="font-size: 12px;color: #998398;text-justify: inter-word;text-align: justify;padding-left: 8px" ><span style="user-select: none">&#160;&#160;</span>{{ item.desc.substring(0,200)+".." }}</p>
                  </div>
                  <router-link :to="{name:'singlenews',params:{id:item.id,title:item.title}}" custom v-slot="{navigate,href}">
                  <a @click="navigate" :href="href" role="link">
                    <img style="cursor: pointer"  v-if="item.image" :src="$store.state.thumbnail+item.image" class="adscardimg"/>
                    <div v-else class="adscardimg" >
                      بدون تصویر
                    </div>
                  </a>
                  </router-link>
                </div>
                <v-divider class="mt-2 mb-3"></v-divider>
                <router-link :to="{name:'singlenews',params:{id:item.id,title:item.title}}" custom v-slot="{navigate,href}">
                <a @click="navigate" :href="href" role="link" class=" textlinkhover d-inline-block defaultcolor ma-0 text-right font-weight-bold" style="cursor:pointer;">ادامه مطلب</a>
                </router-link>
                <p class="adscardtxts float-left ma-0">{{item.createTime | momentHour}}</p>

              </div>
            </div>
            <div>
              <v-btn
                  class="btn mt-2 v-btn--block"
                  style="font-size: 16px !important;"
                  @click="nextpage"
                  :loading="loading"
                  v-if="showmore"
              >
                نمایش بیشتر آگهی ها
              </v-btn>
            </div>
          </div>
        </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
import SideMenu from "@/components/ContantBlock";
import axios from 'axios'
import {momentfilter} from "@/plugins/moment";
export default {
  name: 'App',
  mixins : [momentfilter],
  data() {
    return {
      cats : [],
      selectedcatId : this.$route.query.cat,
      submittedcatId : null,
      str : null,
      submittedstr:null,
      items : [],
      page : 1,
      showtxt : false,
      loading : false,
      showmore:false,
      workloading : true,

    }
  },
  metaInfo() {
    return {
      title:'اخبار',
      link: [{rel: 'canonical', href: this.$store.state.url+'/news'}]
    }
  },
  components : {
    SideMenu,
  },

  created() {

    this.str = this.$route.query.q;

    if(this.$route.query.cat){
      this.selectedcatId = parseInt(this.$route.query.cat).toString();
    }



    axios.get(this.$store.state.api + 'cats').then(response => {
      this.cats = response.data.data;
    })

    axios.get(this.$store.state.api + 'news', {
      params: {
        page : this.page,
        catId : this.selectedcatId,
        str : this.str,
      }
    }).then(response => {
      this.items = response.data.data;
      this.submittedcatId = this.selectedcatId;
      this.submittedstr = this.str;

      axios.get(this.$store.state.api + 'news', {
        params: {
          page : this.page + 1,
          catId : this.selectedcatId,
          str : this.str,
        }
      }).then(response => {
        if(response.data.data.length==0){
          this.showmore=false;
        } else {
          this.showmore=true;
        }
      })

      this.workloading = false;

    })

  },
  watch : {

    '$route'() {
      this.showmore = false;
      this.str = this.$route.query.q;
      this.selectedcatId = this.$route.query.cat;
      this.page =1;
      this.search();

    },
  },
  methods : {
    search(){
      this.$router.push({query:{q:this.str,cat:this.selectedcatId}}).catch(()=>{});
      this.workloading = true;
      this.items = [];
      this.page = 1;
      axios.get(this.$store.state.api + 'news', {
        params: {
          page : this.page,
          catId : this.selectedcatId,
          str : this.str,
        }
      }).then(response => {
        this.items = response.data.data;
        this.submittedcatId = this.selectedcatId;
        this.submittedstr = this.str;

        axios.get(this.$store.state.api + 'news', {
          params: {
            page : this.page + 1,
            catId : this.selectedcatId,
            str : this.str,
          }
        }).then(response => {
          if(response.data.data.length==0){
            this.showmore=false;
          } else {
            this.showmore=true;
          }
        })
        this.workloading = false;
      })
    },
    nextpage(){
      this.loading=true;
      this.page = this.page + 1;
      axios.get(this.$store.state.api + 'news', {
        params: {
          page : this.page,
          catId : this.submittedcatId,
          str : this.submittedstr,
        }
      }).then(response => {
        this.items.push(...response.data.data);
        axios.get(this.$store.state.api + 'news', {
          params: {
            page : this.page + 1,
            catId : this.submittedcatId,
            str : this.submittedstr,
          }
        }).then(response => {
          if(response.data.data.length==0){
            this.showmore=false;
            this.loading=false;
          } else {
            this.showmore=true;
            this.loading=false;
          }
        })

      })
    }
  }
};
</script>


<style lang="scss">

@import "../assets/css/loading";

.centeredtitle {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.adstitle{
  margin-bottom: 1.4vw !important;
  font-size: 5.751vw;
  min-width: 300px;
  user-select: none;
  color: #ffffff;
  text-align: center;
  text-shadow: 0px 6px 5px rgba(0, 0, 0, 0.6);
}

.adssubtitle{
  font-size: 1.725vw;
  min-width: 300px;
  user-select: none;
  color: #ffffff;
  text-align: center;
  text-shadow: 0px 6px 5px rgba(0, 0, 0, 0.6);
  letter-spacing: 0.6px;
}

@media only screen and (max-width: 768px) {
  .adstitle{
    font-size: 44px !important;
  }
  .adssubtitle{
    font-size: 12px !important;
  }
}

.searchbar{
  width: 100%;
  min-height: 98px;
  position: absolute;
  justify-content: space-evenly;
  background-color: #fcfcfc;
  top: -50px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08) !important;
}



.searchbar .v-input__slot{

  background: #f9f9f9 !important;


}

.searchbar .v-label{
  color: #570654 !important;
  font-size: 16px ;
  height: 50px !important;
}

.searchbar .v-input{
  color: #570654 !important;
  font-size: 16px !important;
}

.searchbar .v-input__slot {
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08) !important;
  padding: 0 16px !important;
}

.searchbar .theme--light.v-input input, .theme--light.v-input textarea{
  color: #570654 !important;
}

.searchbar .v-list * , ::before , ::after {
  color: #570654 !important;
}

.searchbar .theme--light.v-select .v-select__selections{
  color: #570654 !important;
  line-height : 30px;
  background-color: #f0f0f0;
}

.searchbar .catsselect .v-input__slot{
  background-color: #f0f0f0 !important;
}

.catsselect{
  width: 100%;
  margin-top: 12.75px;
  margin-bottom: 12.75px;
}

.cityselect{
  width: 48% !important;
}

.catstitle{
  width: 100%;
  margin-top: 12.75px;
}


@media only screen and (min-width: 960px) {
  .catsselect{
    width: 320px;
  }

  .cityselect{
    width: 230px !important;
  }

  .catstitle {
    width: 480px;
    margin-top: 0px;
  }
}

.adscontent{
  padding: 0;
  margin-bottom: 20px;
}

@media only screen and (min-width: 300px){
  .adscontent{
    margin-top: 250px;
  }
}

@media only screen and (min-width: 960px){
  .adscontent{
    margin-top: 96px;
  }
}

.sidecattext{
  font-size: 17px;
  color: #010101;
  cursor: pointer;
}

.adscard{
  width: 420px;
  min-height: 200px;
  background-color: #fcfcfc;
  margin-bottom: 27px;
  border-radius: 8px;
  padding: 15px 20px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08) !important;
}


@media only screen and (max-width: 902px) {
  .adscard{
    width: 100%;
  }
}



@media only screen and (min-width: 960px) {
  .adscard{
    width: 100%;
  }
}



@media only screen and (min-width: 1024px) {
  .adscard{
    width: 335px;
  }
}

@media only screen and (min-width: 1042px) {
  .adscard{
    width: 340px;
  }
}


@media only screen and (min-width: 1092px) {
  .adscard{
    width: 360px;
  }
}


.adscardtitle{
  font-size: 17px;
  color: #181718;
  line-height: 21px;
  min-height: 25px;
}

@media only screen and (min-width: 600px) {
  .adscardtitle{
    min-height: 40px;
  }

}

.adscardtxts{
  color: #181718;
  font-size: 16px;
}

.adscardimg{
  height: 128px;
  width: 128px;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}


</style>
